
import React, { useState, useEffect } from 'react';
import Button from './ui/button';
import withAuth from './data/withAuth';
import withSnowball from './data/withSnowball';
import getEnv from '../lib/getEnv';
import * as anchor from '@project-serum/anchor'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Modal from './ui/modal';
import CircleLoader from './ui/circleLoader';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm';
import { getNFTMetadata } from '../lib/solana/nfts';
import TextField from './ui/textfield';
import { useRouter } from 'next/router';
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { canMintToken } from '../lib/utils/misc';

const stripePromise = loadStripe(
  getEnv() === 'prod'
  ? 'pk_live_rVQi2jnLdbl8n4s3S4fe3cGI00oPIGV2qS'
  : "pk_test_Z0m953x7t7aRSjbccBRT4DvG00cYgqeiXl"
);

function MintButton(props) {
  const {
    user,
    snowball,
    api,
    tokenId,
    mintPassword = null,
    roles = [],

    onMintSucceeded = (() => {}),
  } = props;

  const Router = useRouter();

  // all the data that needs to be gotten
  const [tokenDefinitionOut, setTokenDefinitionOut] = useState(false);
  const [tokenDefinitionError, setTokenDefinitionError] = useState(null);
  const [tokenDefinition, setTokenDefinition] = useState(null);

  const [mintDataOut, setMintDataOut] = useState(false);
  const [mintDataError, setMintDataError] = useState(null);
  const [mintData, setMintData] = useState(null);
  const [mintDataCleanup, setMintDataCleanup] = useState(true);

  const [chainDataOut, setChainDataOut] = useState(false);
  const [chainDataError, setChainDataError] = useState(null);
  const [chainData, setChainData] = useState(null);

  const [mintStatsOut, setMintStatsOut] = useState(false);
  const [mintStatsError, setMintStatsError] = useState(null);
  const [mintStats, setMintStats] = useState(null);

  const [mintRequestOut, setMintRequestOut] = useState(false);
  const [mintRequestError, setMintRequestError] = useState(null);

  const [connection, setConnection] = useState(null);
  const [mintInterval, setMintInterval] = useState(null);
  const [statsInterval, setStatsInterval] = useState(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [setPrice, setSetPrice] = useState(null);
  const [setPriceText, setSetPriceText] = useState('');

  const [mintBlocked, setMintBlocked] = useState(false);

  const getTokenDefinition = async () => {
    setTokenDefinitionOut(true);

    try {
      const td = await api.get(`/token/definition/${tokenId}`);
      setTokenDefinition(td);

      if (tokenDefinition.metadata?.config?.pricingModel === 'pay_once_variable') {
        setSetPriceText(tokenDefinition.metadata?.config?.price);
      }
    } catch (e) {
      setTokenDefinitionError(e);
    }

    setTokenDefinitionOut(false);
  }

  const getMintStats = async () => {
    setMintStatsOut(true);

    try {
      const ms = await api.get(`/token/definition/${tokenId}/stats`);
      setMintStats(ms);
    } catch (e) {
      setMintStatsError(e);
    }

    setMintStatsOut(false);
  }

  const getMintData = async (cleanup = false) => {
    setMintDataOut(true);

    try {
      const { mints } = await api.get(`/token/definition/${tokenId}/mint2${cleanup ? '?cleanup=1' : ''}`);
      const mint = mints.filter(
        m => !['payment_failed', 'webhook_failed'].includes(m.mint2Status)
      )[0];

      setMintData(mint);
      setMintDataCleanup(false);
    } catch (e) {
      setMintDataError(e);
    }

    setMintDataOut(false);
  }

  const getConnection = async () => {
    const conn = new anchor.web3.Connection(
      getEnv() === 'prod'
        ? `https://comet-mainnet-cd9e.mainnet.rpcpool.com`
        : `https://comet-develope-73e4.devnet.rpcpool.com`
    );
    setConnection(conn);
  }

  const getChainData = async () => {
    setChainDataOut(true);

    try {
      const { tokens } = await api.get(
        `/token/query/tokens?address=${snowball.getAddress()}&tokenDefinitionId=${tokenId}`,
        {},
        false,
        {},
        null,
        // 1000,
      );
      setChainData(tokens);
    } catch (e) {
      console.error(e)
      setChainDataError(e);
    }

    setChainDataOut(false);
  }

  const startMintInterval = () => {
    if (!mintInterval) {
      const mi = setInterval(() => {
        getMintData();
        getChainData();
      }, 1500);
      setMintInterval(mi);
    }
  }

  const endMintInterval = () => {
    if (mintInterval) {
      clearInterval(mintInterval);
      setMintInterval(null);
    }
  }

  const onMintStart = async () => {
    setMintRequestOut(true);
    setModalOpen(true);

    const price = tokenDefinition?.metadata?.config?.price;
    if (price > 0) {
      // this is a paid NFT. instead of immediately minting,
      // ask stripe for a payment intent
      if (!stripeClientSecret) {
        let paymentConfig = {
          password: mintPassword,
        };

        if (tokenDefinition?.metadata?.config?.pricingModel === 'pay_once_variable') {
          paymentConfig = {
            setPrice: parseFloat(setPriceText),
          }
        }

        const { clientSecret } = await api.post(
          `/token/definition/${tokenId}/mint2/payment`,
          paymentConfig,
        );
        setStripeClientSecret(clientSecret);
      }
    } else {
      await api.post(
        `/token/definition/${tokenId}/mint2`,
        {
          password: mintPassword,
          // launchQueue: 'AniQueue',
        },
      );
    }

    await getMintData(true);
    setMintRequestOut(false);
  }

  // start by getting initial data
  useEffect(() => {
    if (snowball) {
      getMintData(true);
      getChainData();
    }
    getConnection();
    getTokenDefinition();
    getMintStats();

    if (!statsInterval) {
      setStatsInterval(setInterval(() => {
        getMintStats();
      }, 10000));
    }

    return () => {
      setMintData(null);
      setChainData(null);

      if (statsInterval) {
        clearInterval(statsInterval);
        setStatsInterval(null);
      }
    }
  }, [snowball]);

  useEffect(() => {
    if (tokenDefinition) {
      if (!mintPassword) {
        setMintBlocked(!canMintToken(roles, tokenDefinition, mintPassword));
      }
    }
  }, [roles, tokenDefinition, mintPassword]);

  // conditions for starting + stopping mint interval
  useEffect(() => {
    if (mintData) {
      setPaymentProcessing(false);
      if (hasToken) setStripeClientSecret(null);

      if (
        mintData.mint2Status === 'mint_failed'
        || mintData.status === 'FAILED:NORETRY'
      ) {
        if (mintInterval) endMintInterval();
      } else if (
        mintData.mint2Status === 'mint_pending'
        || mintData.mint2Status === 'payment_processing'
        // || (mintData.mint2Status === 'mint_succeeded' && !(chainData && chainData.length))
      ) {
        // token minting is not finished/finalized
        setStripeClientSecret(null);
        if (!mintInterval) startMintInterval();
      } else if (
        mintData.mint2Status === 'mint_succeeded'
        && chainData
        && chainData.length
      ) {
        if (mintInterval) {
          endMintInterval();
          setModalOpen(true);
        }
        onMintSucceeded();
      }
    }
  }, [mintData]);

  const loading = (
    !user
    || !snowball
    || tokenDefinitionOut
    || (mintDataOut && !mintData)
    || (chainDataOut && !chainData)
    // || mintStatsOut
    || mintRequestOut
  );

  const minting = (
    ['mint_pending', 'payment_processing'].includes(mintData?.mint2Status)
    // || (mintData?.mint2Status === 'mint_succeeded' && !(chainData && chainData.length))
  );
  const mintSucceeded = (
    ['mint_succeeded'].includes(mintData?.mint2Status)
  );

  const hasToken = (chainData && !!chainData.length);

  const maxSupply = parseInt(tokenDefinition?.metadata?.config?.maxSupply);
  const numMinted = mintStats?.minted;
  const mintedOut = (
    typeof maxSupply === 'number'
    && typeof numMinted === 'number'
    && numMinted >= maxSupply
    && tokenDefinition
    && !tokenDefinition.metadata?.config?.infiniteSupply
    && !hasToken
  );

  let buttonContent, buttonOnClick, buttonRightIcon;
  let modalContent;

  if (loading) {
    modalContent = (
      <div className='w-full flex flex-col gap-3 p-3 items-center justify-center -mt-6'>
        <CircleLoader size={50} />
      </div>
    );
  } else if (mintBlocked && !hasToken) {
    buttonContent = (
      <>
        Mint is private
      </>
    );
  } else if (minting && !hasToken && !mintSucceeded) {
    buttonContent = (
      <>
        <span className='-ml-1 -mt-0.5'>
          <CircleLoader size={18} />
        </span>
        <span className='ml-2'>Minting</span>
      </>
    );
    buttonOnClick = () => {
      setModalOpen(true);
    };

    let mintText = (
      <div className='text-center'>
        <p>Minting...</p>
        <p className='sm'>(you can close this box)</p>
      </div>
    );
    // In cases where Solana is slow for example, the mint may fail the first
    // time around. In those cases, show an error message so the user isn't
    // freaked out
    if (mintData.mint2Status === 'mint_pending' && mintData.status === 'FAILED') {
      mintText = (
        <div className='text-center'>
          <p>Experiencing network congestion, hang in there...</p>
        </div>
      )
    }

    modalContent = (
      <div className='w-full flex flex-col gap-3 p-3 items-center justify-center -mt-6'>
        <CircleLoader size={50} />
        <p className='font-medium text-lg'>{mintText}</p>
      </div>
    );
  } else if (
    tokenDefinition?.metadata?.config?.pricingModel === 'pay_once_variable'
    && !setPrice
    && !minting
    && !hasToken
    && !stripeClientSecret
    && !mintSucceeded
  ) {
    buttonContent = `Buy (min $${tokenDefinition.metadata?.config?.price})`;
    buttonOnClick = () => {
      setModalOpen(true);
    };
    modalContent = (
      <div className='-mt-10 flex flex-col gap-4'>
        <h2 className='text-slate-900 font-medium text-xl mb-4'>What's your contribution?</h2>
        <TextField
          size="lg"
          autoFocus
          label="Contribution amount ($)"
          type="number"
          min={tokenDefinition.metadata?.config?.price}
          value={setPriceText}
          leftIcon={faDollarSign}
          onChange={(e) => { setSetPriceText(e.target.value); }}
        />
        <Button
          rightIcon={faArrowRight}
          onClick={onMintStart}
          disabled={!setPriceText || parseInt(setPriceText) < tokenDefinition.metadata?.config?.price}
        >
          Next
        </Button>
      </div>
    );
  } else if (paymentProcessing) {
    buttonContent = (
      <span className='-mt-0.5'>
        <CircleLoader size={18} />
      </span>
    )
    buttonOnClick = () => {
      setModalOpen(true);
    };
    modalContent = (
      <div className='w-full flex flex-col gap-3 p-3 items-center justify-center -mt-6'>
        <CircleLoader size={50} />
        <p className='font-medium text-lg'>Processing payment...</p>
      </div>
    );
  } else if (stripeClientSecret && !hasToken && !minting && modalOpen) {
    buttonContent = (
      <span className='-mt-0.5'>
        <CircleLoader size={18} />
      </span>
    )
    buttonOnClick = () => {
      setModalOpen(true);
    };
    const buyingPrice = tokenDefinition?.metadata?.config?.pricingModel === 'pay_once_variable'
      ? parseFloat(setPriceText)
      : tokenDefinition?.metadata?.config?.price
    modalContent = (
      <div className='-mt-10'>
        <h2 className='text-slate-900 font-medium text-xl mb-4'>Buying {tokenDefinition?.name} for ${buyingPrice}</h2>
        <Elements options={{ appearance: { theme: 'flat' }, clientSecret: stripeClientSecret }} stripe={stripePromise}>
          <CheckoutForm
            onSuccess={() => {
              setPaymentProcessing(true);
              startMintInterval();
            }}
          />
        </Elements>
      </div>
    );
  } else if (hasToken) {
    buttonContent = 'View';
    buttonRightIcon = faArrowRight;
    buttonOnClick = () => {
      Router.push('/wallet');
    }
    modalContent = (
      <div className='w-full flex items-center justify-center -mt-4 flex-col gap-3'>
        <h3 className='font-medium text-lg mb-2'>Got it!</h3>
        <img src={chainData[0].image} className='w-40 shadow-lg mb-2' />
        <a href={`/wallet`}>
          <Button
            className='w-full'
            rightIcon={faArrowRight}
            // rounded="xl"
          >
            View in gallery
          </Button>
        </a>
      </div>
    )
  } else if (mintedOut) {
    buttonContent = 'All claimed';
  } else if (mintSucceeded) {
    // mint has succeeded but hasToken is false...
    buttonContent = (
      <>
        <span className='-ml-1 -mt-0.5'>
          <CircleLoader size={18} />
        </span>
        <span className='ml-2'>Minting</span>
      </>
    );
    buttonOnClick = () => {
      setModalOpen(true);
    };

    let mintText = 'Minting...';
    // In cases where Solana is slow for example, the mint may fail the first
    // time around. In those cases, show an error message so the user isn't
    // freaked out
    if (mintData.mint2Status === 'mint_pending' && mintData.status === 'FAILED') {
      mintText = 'Experiencing network congestion, hang in there...'
    }

    modalContent = (
      <div className='w-full flex flex-col gap-3 p-3 items-center justify-center -mt-6'>
        <CircleLoader size={50} />
        <p className='font-medium text-lg'>{mintText}</p>
      </div>
    );
  } else {
    // buttonContent = 'Mint';
    if (tokenDefinition?.metadata?.config?.price > 0) {
      if (tokenDefinition?.metadata?.config?.pricingModel === 'pay_once_variable') {
        buttonContent = `Buy (min $${tokenDefinition.metadata?.config?.price})`;
      } else {
        buttonContent = `Buy for $${tokenDefinition.metadata?.config?.price}`;
      }
    } else {
      buttonContent = 'Claim for free';
    }
    buttonOnClick = onMintStart;
  }

  const button = (
    <Button
      loading={loading}
      disabled={mintedOut || (mintBlocked && !hasToken)}
      onClick={buttonOnClick}
      rightIcon={buttonRightIcon}
    >
      {buttonContent}
    </Button>
  );

  const modal = (
    <Modal
      open={modalOpen}
      onClose={() => { setModalOpen(false); setStripeClientSecret(null); }}
    >
      {modalContent}
    </Modal>
  );

  return (
    <>
      {modal}
      {button}

      {
        mintStats && (
          <span className='w-full text-center -mt-2.5 -mb-1.5 text-sm font-medium text-slate-500'>
            <div className='w-full'>
              {
                tokenDefinition && tokenDefinition.metadata?.config?.infiniteSupply
                  ? (
                    <>
                      {numMinted} claimed
                    </>
                  )
                  : (
                    <>
                    {/* New Years Party 2023. Commenting out for now.  */}
                      {/* {maxSupply - numMinted}/{maxSupply} left */}
                    </>
                  )
              }
            </div>

            {
              tokenDefinition && tokenDefinition.metadata?.config?.pricingModel === 'pay_once_variable' && (
                <div className='w-full -mt-1.5'>
                  ${mintStats.totalPaid} total
                </div>
              )
            }

          </span>
        )
      }
    </>
  )
};

export default MintButton;
