import * as anchor from '@project-serum/anchor'
import * as splToken from '@solana/spl-token'
import { Metadata } from '@metaplex-foundation/mpl-token-metadata'
import axios from 'axios'

const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
)

export const getNFTAccounts = async ({ address, chainId }) => {
  const connection = new anchor.web3.Connection(
    chainId === 101
      ? `https://comet-mainnet-cd9e.mainnet.rpcpool.com`
      : `https://comet-develope-73e4.devnet.rpcpool.com/2b0a42cc-b72c-498e-a227-de620350aebf`,
    'confirmed'
  )

  const accounts = await connection.getParsedProgramAccounts(
    new anchor.web3.PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
    {
      filters: [
        {
          dataSize: 165, // number of bytes
        },
        {
          memcmp: {
            offset: 32, // number of bytes
            bytes: address, // base58 encoded string
          },
        },
      ],
    }
  )

  return accounts
}

export const getNFTMetadata = async ({ mintPubkey, chainId }) => {
  const connection = new anchor.web3.Connection(
    chainId === 101
      ? `https://comet-mainnet-cd9e.mainnet.rpcpool.com`
      : `https://comet-develope-73e4.devnet.rpcpool.com/2b0a42cc-b72c-498e-a227-de620350aebf`,
    'confirmed'
  )

  const pk = new anchor.web3.PublicKey(mintPubkey)
  const [tokenmetaPubkey] = await anchor.web3.PublicKey.findProgramAddress(
    [
      Buffer.from('metadata'),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      pk.toBuffer(),
    ],
    TOKEN_METADATA_PROGRAM_ID
  )

  try {
    // console.log(1);
    const result = await Metadata.fromAccountAddress(
      connection,
      tokenmetaPubkey
    )
    const uri = result.data.uri.replace(/\0/g, '')

    const md = await axios.get(uri)
    return {
      data: md.data,
      collection: result.collection,
    }
  } catch (e) {
    console.error(e)
    return null
  }
  // console.log(tokenmeta);
}

export const transferNFT = async (
  tokenDefId,
  mintAddr,
  destAddr,
  snowball,
  env
) => {
  // const rpcUrl = `https://api.withcomet.com/comet/rpc/solana/${snowball.getChainId()}`
  const rpcUrl = snowball.getChainId() === 101
    ? `https://comet-mainnet-cd9e.mainnet.rpcpool.com`
    : `https://comet-develope-73e4.devnet.rpcpool.com/2b0a42cc-b72c-498e-a227-de620350aebf`

  const connection = new anchor.web3.Connection(rpcUrl)

  const mint = new anchor.web3.PublicKey(mintAddr)
  const owner = new anchor.web3.PublicKey(snowball.snowball.address)
  const dest = new anchor.web3.PublicKey(destAddr)
  const payer =
    env === 'prod'
      ? new anchor.web3.PublicKey('CKCwsJFAyiEV1rmfEZvDnBs48NUEr8u4ChFKGWXnvb6N')
      : new anchor.web3.PublicKey('4pZwhNDR3EeAVU3rRs1qTuoYZGNipoCLo8owtKy1HFG8')

  const tx = new anchor.web3.Transaction({
    feePayer: payer,
  })

  const sourceAta = await splToken.Token.getAssociatedTokenAddress(
    splToken.ASSOCIATED_TOKEN_PROGRAM_ID,
    splToken.TOKEN_PROGRAM_ID,
    mint,
    owner
  )
  const destAta = await splToken.Token.getAssociatedTokenAddress(
    splToken.ASSOCIATED_TOKEN_PROGRAM_ID,
    splToken.TOKEN_PROGRAM_ID,
    mint,
    dest
  )
  const destAtaInfo = await connection.getAccountInfo(destAta)
  if (!destAtaInfo) {
    tx.add(
      splToken.Token.createAssociatedTokenAccountInstruction(
        splToken.ASSOCIATED_TOKEN_PROGRAM_ID,
        splToken.TOKEN_PROGRAM_ID,
        mint,
        destAta,
        dest,
        payer
      )
    )
  }

  const ix = splToken.Token.createTransferCheckedInstruction(
    splToken.TOKEN_PROGRAM_ID,
    sourceAta,
    mint,
    destAta,
    owner,
    [],
    1,
    0
  )
  tx.add(ix)

  const blockhash = (await connection.getLatestBlockhash('finalized')).blockhash

  tx.recentBlockhash = blockhash

  snowball.signSolanaTransaction(tx)

  await snowball.api.post(`/token/${tokenDefId}/transfer`, {
    mintAddr: mint.toBase58(),
    ownerAddr: owner.toBase58(),
    destAddr: dest.toBase58(),
    transactionJSON: JSON.parse(stringifyTransaction(tx)),
  })
}

const stringifyTransaction = (tx) => {
  const items = JSON.parse(JSON.stringify(tx.instructions))

  for (let i = 0; i < items.length; i += 1) {
    // Hack so that strings are equal between browser + node representation
    items[i] = {
      ...items[i],
      data: [...items[i].data],
    }
  }

  return JSON.stringify({
    ...tx,
    instructions: items,
  })
}
